<template>
  <div class="chain-info">
    <el-dialog :append-to-body="true"
               :visible="visible"
               destroy-on-close
               :close-on-click-modal="false"
               :show-close="false"
               @close="handleClose"
               top="30vh"
               class="chain-info-dialog">
      <div slot="title"
           class="chain-info-form__header">
        <slot name="hashTitle">
          <span>哈希</span>
        </slot>
      </div>
      <div class="chain-info-form__body">
        <slot name="hashInfo">{{hashInfo.hash}}</slot>
      </div>
      <div slot="footer"
           class="chain-info-form__footer">
        <el-button type="primary"
                   class="submit"
                   @click="handleClose">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false
    }
  },
  props: {
    hashInfo: {
      type: Object,
      default: () => { }
    }
  },
  mounted() {
    this.visible = true
  },
  methods: {
    handleClose() {
      this.visible = false
      this.$emit('CloseChainInfo')
    }
  }
}
</script>

<style lang="scss">
.chain-info-dialog {
  .el-dialog {
    box-shadow: 0px 10px 25px 0px rgba(36, 37, 42, 0.15);
    border-radius: 4px;
    overflow: hidden;
    &__header {
      padding: 0;
    }
    &__body {
      padding: 49px 40px 43px 36px;
    }
    &__footer {
      padding: 0;
    }
  }
}
.chain-info {
  &-form {
    &__header {
      height: 48px;
      line-height: 48px;
      padding-left: 23px;
      font-size: 16px;
      font-weight: 600;
      background: #eff1f4;
      user-select: none;
    }
    &__body {
      font-size: 14px;
    }
    &__footer {
      display: flex;
      justify-content: flex-end;
      height: 52px;
      padding: 8px 20px;
      background: rgba($color: #eff1f4, $alpha: 0.5);
      .el-button {
        font-weight: 600;
        padding: 8px 20px;
        & + .el-button {
          margin-left: 16px;
        }
      }
    }
  }
}
</style>
