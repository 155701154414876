<template>
  <div class="units-addition">
    <el-dialog :append-to-body="true"
               :visible="visible"
               destroy-on-close
               :close-on-click-modal="false"
               :show-close="false"
               :width="dialogWidth"
               @close="handleCancel"
               top="30vh"
               class="units-addition-dialog">
      <div slot="title"
           class="units-addition-form__header">
        <span>新增</span>
      </div>
      <el-form ref="unitsAdditionForm"
               :model="unitsAdditionForm"
               label-position="right"
               label-width="auto"
               label-suffix="："
               :rules="rules">
        <div class="units-addition-form__body">
          <el-form-item label="记账单位名称"
                        prop="name">
            <el-input v-model="unitsAdditionForm.name"
                      placeholder="请输入记账单位名称"
                      maxlength="20"
                      clearable></el-input>
          </el-form-item>
          <el-form-item label="总数"
                        prop="amount">
            <el-input v-model="unitsAdditionForm.amount"
                      placeholder="请输入总数"
                      maxlength="10"
                      clearable
                      @input="limitPositiveInteger"></el-input>
          </el-form-item>
          <el-form-item label="有效期"
                        prop="expiry"
                        v-if="fixScreen">
            <el-date-picker v-model="unitsAdditionForm.expiry"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :picker-options="pickerOptions">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="有效期(开始)"
                        prop="expiryStart"
                        v-if="!fixScreen">
            <el-date-picker v-model="unitsAdditionForm.expiryStart"
                            type="date"
                            placeholder="开始日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="有效期(结束)"
                        prop="expiryEnd"
                        v-if="!fixScreen">
            <el-date-picker v-model="unitsAdditionForm.expiryEnd"
                            type="date"
                            placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer"
           class="units-addition-form__footer">
        <el-button class="cancel"
                   @click="handleCancel">取消</el-button>
        <el-button type="primary"
                   class="submit"
                   @click="handleSubmit('unitsAdditionForm')">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { deepClone } from '@/utils/deepClone.js'
export default {
  data() {
    // 校验记账单位名称
    let validateUnitName = (rule, value, callback) => {
      // TODO: 记账单位名称不能重名
      if (!value) {
        return callback(new Error('请输入记账单位名称'))
      } else {
        return callback()
      }
    }
    // 校验记账单位数量
    let validateUnitAmount = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入总数'))
      } else {
        return callback()
      }
    }
    // 校验有效期
    let validateUnitExpiry = (rule, value, callback) => {
      for (let d of value) {
        if (!d) {
          return callback(new Error('请选择有效日期'))
        }
      }
      return callback()
    }
    // 校验有效期的开始日期
    let validateUnitExpiryStart = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择有效日期'))
      } else {
        return callback()
      }
    }
    // 校验有效期的结束日期
    let validateUnitExpiryEnd = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择有效日期'))
      } else if (this.unitsAdditionForm.expiryStart.valueOf() >= this.setDateEnd(this.unitsAdditionForm.expiryEnd).valueOf()) {
        return callback(new Error('结束日期不能早于开始日期'))
      } else {
        return callback()
      }
    }
    return {
      unitsAdditionForm: {
        name: '',
        amount: '',
        expiry: ['', ''],
        expiryStart: '',
        expiryEnd: ''
      },
      pickerOptions: {
        // 有效期不能选择当天之前的时间
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7
        }
      },
      // 校验规则
      rules: {
        name: [
          { validator: validateUnitName, trigger: 'blur' }
        ],
        amount: [
          { validator: validateUnitAmount, trigger: 'blur' }
        ],
        expiry: [
          { validator: validateUnitExpiry, trigger: 'blur' }
        ],
        expiryStart: [
          { validator: validateUnitExpiryStart, trigger: 'blur' }
        ],
        expiryEnd: [
          { validator: validateUnitExpiryEnd, trigger: 'blur' }
        ]
      },
      dialogWidth: '0',
      visible: false,
      fixScreen: true // 当屏幕尺寸大于414时，正常显示有效期选择；当屏幕尺寸小于414时，将有效期的开始日期和结束日期拆分显示
    }
  },
  mounted() {
    this.visible = true
    this.clearForm()
    this.setDialogWidth()
    // 初始化有效期的开始日期为当天的0点0分0秒
    this.unitsAdditionForm.expiry[0] = this.initDateStart()
    this.unitsAdditionForm.expiryStart = this.initDateStart()
    // 监听浏览器窗口大小变化，用于新增记账单位窗口的自适应
    window.addEventListener('resize', () => {
      if (this.visible) {
        return this.setDialogWidth()
      }
    }, false)
  },
  // 移除事件监听
  beforeDestroy() {
    window.removeEventListener('resize', () => {
      if (this.visible) {
        return this.setDialogWidth()
      }
    }, false)
  },
  watch: {
    visible() {
      if (this.visible) {
        this.setDialogWidth()
      }
    }
  },
  methods: {
    // 获取当天日期，并设置时间为00:00:00
    initDateStart() {
      let d = new Date()
      d.setHours(0)
      d.setMinutes(0)
      d.setSeconds(0)
      return d
    },
    setDateEnd(d) {
      d.setHours(23)
      d.setMinutes(59)
      d.setSeconds(59)
      return d
    },
    // 清空窗口的输入内容
    clearForm() {
      this.unitsAdditionForm.name = ''
      this.unitsAdditionForm.amount = ''
      this.unitsAdditionForm.expiry = [this.initDateStart(), '']
      this.unitsAdditionForm.expiryStart = this.initDateStart()
      this.unitsAdditionForm.expiryEnd = ''
    },
    // 点击取消按钮时的操作
    handleCancel() {
      this.$emit('hideUnitsAddition')
    },
    // 点击确定按钮时的内容校验和数据弹出
    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!this.fixScreen) {
            // 若屏幕尺寸小于414时，将拆分输入的有效期合并
            this.unitsAdditionForm.expiry[0] = this.unitsAdditionForm.expiryStart
            this.unitsAdditionForm.expiry[1] = this.setDateEnd(this.unitsAdditionForm.expiryEnd)
          } else {
            this.unitsAdditionForm.expiry[1] = this.setDateEnd(this.unitsAdditionForm.expiry[1])
          }
          let unitData = deepClone(this.unitsAdditionForm)
          this.$emit('createUnit', unitData)
        } else {
          return false
        }
      })
    },
    // 设置不同屏幕下的窗口宽度
    setDialogWidth() {
      let windowWidth = window.innerWidth
      if (windowWidth <= 320) {
        this.dialogWidth = '99%'
        this.fixScreen = false
      } else if (windowWidth <= 414) {
        this.dialogWidth = '90%'
        this.fixScreen = false
      } else if (windowWidth > 414) {
        this.dialogWidth = '440px'
        this.fixScreen = true
      }
    },
    // 控制记账单位只能输入正整数
    limitPositiveInteger() {
      if (this.unitsAdditionForm.amount.length === 1) {
        this.unitsAdditionForm.amount = this.unitsAdditionForm.amount.replace(/[^1-9]/g, '')
      } else {
        this.unitsAdditionForm.amount = this.unitsAdditionForm.amount.replace(/\D/g, '')
      }
    }
  }
}
</script>

<style lang="scss">
.units-addition-dialog {
  .el-dialog {
    box-shadow: 0px 10px 25px 0px rgba(36, 37, 42, 0.15);
    border-radius: 4px;
    overflow: hidden;
    &__header {
      padding: 0;
    }
    &__body {
      padding: 49px 40px 44px 36px;
    }
    &__footer {
      padding: 0;
    }
  }
}
.units-addition {
  &-form {
    &__header {
      height: 48px;
      line-height: 48px;
      padding-left: 23px;
      font-size: 16px;
      font-weight: 600;
      background: #eff1f4;
      user-select: none;
    }
    &__body {
      font-size: 14px;
      .el-form-item {
        margin-bottom: 20px;
        &__label {
          color: #222b45;
          font-weight: 600;
          user-select: none;
        }
        .el-input {
          width: 260px;
        }
        .el-date-editor {
          width: 260px;
          .el-range-separator {
            width: 12%;
            font-weight: 600;
            padding: 0;
          }
        }
      }
    }
    &__footer {
      display: flex;
      justify-content: flex-end;
      height: 52px;
      padding: 8px 20px;
      background: rgba($color: #eff1f4, $alpha: 0.5);
      .el-button {
        font-weight: 600;
        padding: 8px 20px;
        & + .el-button {
          margin-left: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .units-addition-form__body {
    .el-form-item {
      .el-form-item__content {
        .el-input {
          width: 235px;
        }
        .el-date-editor {
          width: 235px;
        }
      }
    }
  }
  .units-addition-dialog {
    .el-dialog__body {
      padding: 41px 10px;
    }
  }
}

@media screen and (max-width: 375px) {
  .units-addition-form__body {
    .el-form-item {
      .el-form-item__content {
        .el-input {
          width: 200px;
        }
        .el-date-editor {
          width: 200px;
        }
      }
    }
  }
  .units-addition-dialog {
    .el-dialog__body {
      padding: 41px 5px;
    }
  }
}

@media screen and (max-width: 320px) {
  .units-addition-form__body {
    .el-form-item {
      .el-form-item__content {
        .el-input {
          width: 183px;
        }
        .el-date-editor {
          width: 183px;
        }
      }
    }
  }
}
</style>
