export default {
  data() {
    return {
      showInfo: false,
      hashInfo: {}
    }
  },
  methods: {
    closeChainInfo() {
      this.$bus.$emit('clearBlur')
      this.showInfo = false
    },
    showChainInfo(row) {
      this.hashInfo = row
      this.showInfo = true
      this.$bus.$emit('blurScreen')
    }
  }
}
